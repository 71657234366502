<ng-container *transloco="let t">
    <div>
        <div class="flex">
            <h2 mat-dialog-title><b>{{t('order')}} #</b>{{order?.orderNumber}}</h2>

            <div class="flex items-center justify-end w-full max-w-3xl mx-auto pt-6">
            </div>
            <button
                mat-icon-button
                [matTooltip]="'Close'"
                (click)="_matDialogRef.close()">
                <mat-icon
                    class="text-black"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="grid mt-5">
            <div
                class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5">
                <div class="hidden sm:block">{{t('product')}}</div>
                <div class="hidden sm:block">{{t('quantity')}}</div>
                <div class="hidden sm:block">{{t('price')}}</div>
                <div class="hidden sm:block">{{t('discount')}}</div>
                <div class="hidden sm:block">{{t('reward')}}</div>
                <div class="hidden sm:block">{{t('sum')}}</div>
            </div>
            <ng-container
                *ngFor="let item of order?.orderItems; let i = index; trackBy: trackByFn">
                <a

                    [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !item || item.id !== order.id,
                            'bg-primary-50 dark:bg-hover': item && item.id === order.id}"
                    [routerLink]="[]">
                    <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                        <div class="truncate">
                            {{item.productName}}
                            <ul>
                                <li>{{ item?.properties?.unit?.name }} (x{{item?.properties?.unit?.contents}})</li>
                            </ul>
                        </div>
                        <div class="truncate">
                            {{item.quantity}}
                        </div>
                        <div class="truncate">
                            {{item.price}}
                        </div>
                        <div class="truncate">
                            {{item.discount}}
                        </div>
                        <div class="truncate">
                            {{item.reward || 0}}<span style="color: red"> B</span>
                        </div>
                        <div class="truncate">
                            ₽ {{item.quantity * (item?.price * ((100 - item?.discount) / 100)) * (+item?.properties?.unit?.contents)}}
                        </div>
                    </div>
                </a>
            </ng-container>
            <a>
                <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                    <div class="truncate">
                        <b>{{t('total')}}</b>
                    </div>
                    <div class="truncate">
                    </div>
                    <div class="truncate">
                    </div>
                    <div class="truncate">
                    </div>
                    <div class="truncate">
                        {{order?.rewardTotal || 0}} <span style="color: red"> B</span>
                    </div>
                    <div class="truncate">
                        ₽ {{order?.orderTotal}}
                    </div>
                </div>
            </a>
        </div>

        <div class="flex mt-5 border-b">
            <div class="grid items-center gap-4 py-3 px-6 md:px-8">
                <label><b>{{t('comment')}}</b></label>
                <textarea rows="4" cols="75" style="resize: none;" [value]="order?.comment" readonly></textarea>
            </div>
        </div>
        <div class="flex mt-5" style="justify-content: space-around">
            <div class="flex-column border-t">
                <div><b>{{t('supplier')}}</b></div>
                <h1 class="mt-2">{{order?.supplier?.companyName}}</h1>
                <h1 class="mt-2">{{order?.supplier?.companyName}}</h1>
                <h1>{{order?.supplier.address.country}}</h1>
                <h1>{{order?.supplier.address.city}}</h1>
                <h1>{{order?.supplier.address.address_1}}</h1>
            </div>
            <div class="flex-column border-t">
                <div><b>{{t('shop')}}</b></div>
                <h1 class="mt-2">{{order?.shop.shopName}}</h1>
                <h1>{{order?.shop.deliveryAddress.country}}</h1>
                <h1>{{order?.shop.deliveryAddress.city}}</h1>
                <h1>{{order?.shop.deliveryAddress.address_1}}</h1>
            </div>
        </div>
        <div class="flex items-center justify-between mt-5">
            <div><b>{{t('orderStatus')}}: </b>{{t(order?.status)}}</div>
        </div>
    </div>
</ng-container>
