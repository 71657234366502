import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Observable, Subject} from "rxjs";
import {appConfig} from "../../core/config/app.config";
import {Activity} from "../../modules/activities/activities.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {FormControl} from "@angular/forms";
import {ActivitiesService} from "../../modules/activities/activities.service";
import {ActivitiesLevelEnum} from "../../modules/activities/activities.component";

@Component({
    selector: 'app-activities-by-id',
    templateUrl: './activities-by-id.component.html'
})
export class ActivitiesByIdComponent implements OnInit {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    @Input() customerId: string;
    @Input() supplierId: string;
    activities: any[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dateFormat = appConfig.dateFormat;
    displayedColumns = ['id', 'date', 'status', 'user', 'message'];
    query = {
        page: 1,
        limit: 10,
        orderBy: 'log.id',
        orderDirection: 'desc',
        supplier: '',
        customer: ''
    }
    activities$: Observable<Activity[]>;
    pagination: Pagination;
    searchInputControl: FormControl = new FormControl();
    isLoading: boolean = false;
    activitiesLevelType = ActivitiesLevelEnum;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public _activityService: ActivitiesService)
    {
    }

    ngOnInit(): void {
        this.query.customer = this.customerId;
        this.query.supplier = this.supplierId;
        this.getLogs(this.query);
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private getLogs(query): void {
        this.isLoading = true;
        this._activityService.getActivities(query).subscribe({
            next: (res) => {
                this.activities = res.payload.content;
                this.pagination = res.payload.pagination;
            }
        })
            .add(() => {
                this.isLoading = false;
                this._changeDetectorRef.markForCheck()
            })
    }

    onPaginateChange(event: PageEvent) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getLogs(this.query);
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this.getLogs(this.query);
    }
}
