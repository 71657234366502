import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, Inject,
    OnDestroy,
    OnInit, Renderer2, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {OrdersService} from "../orders.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Overlay} from "@angular/cdk/overlay";
import {Subject} from "rxjs";
import {OrderEntity} from "../order.types";
import {Pagination} from "../../../core/interfaces/commmon.interfaces";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmPopupComponent} from "../../../shared/confirm-popup/confirm-popup.component";
import {FuseAlertType} from "../../../../@fuse/components/alert";
import {TranslocoService} from "@ngneat/transloco";
import {OrderStatusesEnum} from "../../../core/enums/order-statuses.enum";

@Component({
    selector: 'order-details',
    templateUrl: 'order-details.component.html',
    styles         : [
        /* language=SCSS */
        `
            .inventory-grid {
                grid-template-columns: auto 48px 40px;

                @screen sm {
                    grid-template-columns: auto 48px 112px 72px;
                }

                @screen md {
                    grid-template-columns: auto 112px 48px 112px 112px 112px 112px 112px;
                }

                @screen lg {
                    grid-template-columns: auto 112px 48px 112px 112px 112px 112px 112px ;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderDetailsComponent implements OnInit, OnDestroy {
    editMode: boolean = false;
    order: OrderEntity;
    supplierForm: FormGroup;
    orders: OrderEntity[];
    pagination: Pagination;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    imagePath: string;
    orderStatuses = OrderStatusesEnum;
    isOrderChanged: boolean;
    reqData: any[] = [];
    dialogRef: MatDialogRef<ConfirmPopupComponent>
    showAlert: boolean;
    alert: { type: FuseAlertType; message: string } = {
        type: 'error',
        message: ''
    };

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _ordersService: OrdersService,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _transLocoService: TranslocoService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        public _matDialogRef: MatDialogRef<OrderDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
        this.getOrderById();
    }

    ngOnInit() {

    }

    private getOrderById(): void {
        this._ordersService.getOrderById(this.data).subscribe(res => {
            this.order = res;
            this.reqData = res.orderItems;
            this._changeDetectorRef.detectChanges();
        })
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    edit(e: any, row: any): void {
        row.quantity = +e.target?.value;
        this.isOrderChanged = +e.vtarget?.value != row.quantity;
    }


    change(): void {

    }
}
