import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmPopupComponent} from './confirm-popup/confirm-popup.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {NumberWithSpacesPipe} from "../../@fuse/pipes/number-with-spaces.pipe";
import {TranslocoModule} from "@ngneat/transloco";
import { ProductsByIdComponent } from './products-by-id/products-by-id.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatPaginatorModule} from "@angular/material/paginator";
import {TextTruncatePipe} from "../../@fuse/pipes/truncate-pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { OrdersByIdComponent } from './orders-by-id/orders-by-id.component';
import { ShopsByIdComponent } from './shops-by-id/shops-by-id.component';
import { UsersByIdComponent } from './users-by-id/users-by-id.component';
import { PaymentsByIdComponent } from './payments-by-id/payments-by-id.component';
import { ActivitiesByIdComponent } from './activities-by-id/activities-by-id.component';
import { PartnershipByIdComponent } from './partnership-by-id/partnership-by-id.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatTableModule,
        MatButtonModule,
        MatSortModule,
        MatProgressBarModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        TranslocoModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NumberWithSpacesPipe,
        ProductsByIdComponent,
        TextTruncatePipe,
        OrdersByIdComponent,
        ShopsByIdComponent,
        UsersByIdComponent,
        PaymentsByIdComponent,
        ActivitiesByIdComponent,
        PartnershipByIdComponent
    ],
    declarations: [
        TextTruncatePipe,
        ConfirmPopupComponent,
        NumberWithSpacesPipe,
        ProductsByIdComponent,
        OrdersByIdComponent,
        ShopsByIdComponent,
        UsersByIdComponent,
        PaymentsByIdComponent,
        ActivitiesByIdComponent,
        PartnershipByIdComponent
    ]
})
export class SharedModule {
}
