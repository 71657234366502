import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import {UserEntity} from "../../modules/user/user.types";
import {appConfig} from "../config/app.config";
import {CreateSupplierUserReqModel} from "../../modules/supplier/supplier-types";
import {ApiResultModel} from "../interfaces/api-res.interface";

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(private _httpClient: HttpClient)
    {
    }

    set user(value: User)
    {
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/auth').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/auth', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    getUserById(id: string): Observable<UserEntity>
    {
        return this._httpClient.get<any>(`${appConfig.baseUrl}/users/${id}`).pipe(
            tap((response) => {
                this._user.next(response.payload);
            })
        );
    }

    editUser(id: string, data: CreateSupplierUserReqModel): Observable<ApiResultModel<any>> {
        return this._httpClient.patch<any>(`api/users/update/${id}`, data);
    }
}
