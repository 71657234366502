import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {debounceTime, Observable, Subject, takeUntil} from "rxjs";
import {OrderEntity} from "../../modules/orders/order.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {FormControl} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {OrdersService} from "../../modules/orders/orders.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {OrderDetailsComponent} from "../../modules/orders/order-details/order-details.component";

@Component({
    selector: 'app-orders-by-id',
    templateUrl: './orders-by-id.component.html'
})

export class OrdersByIdComponent implements OnInit{
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    @Input() customerId: string;
    @Input() supplierId: string;
    @Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    orders$: Observable<OrderEntity[]>;
    dialogRef: MatDialogRef<OrderDetailsComponent>;
    pagination: Pagination;
    searchInputControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isLoading: boolean = false;
    imagePath: string;
    displayedColumns = ['createdAt', 'updatedAt', 'orderNumber', 'orderTotal', 'rewardTotal', 'status', 'action'];
    query = {
        page: 1,
        limit: 10,
        orderBy: 's.id',
        orderDirection: 'asc',
        search: '',
        customer: '',
        supplier: ''
    }

    constructor(private _ordersService: OrdersService,
                private _changeDetectorRef: ChangeDetectorRef,
                private dialog: MatDialog) {
    }

    ngOnInit(): void
    {
        this.query.customer = this.customerId;
        this.query.supplier = this.supplierId;
        this.imagePath = environment.imagePath;
        this.orders$ = this._ordersService.orders$;
        this._ordersService.getOrders(this.query).subscribe();
        this._ordersService.pagination$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((pagination: Pagination) => {
                this.pagination = pagination;
                this._changeDetectorRef.markForCheck();
            })

        this.searchOrders();
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this._ordersService.getOrders(this.query).subscribe();
    }

    onPaginateChange(event: any) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this._ordersService.getOrders(this.query).subscribe();
    }

    private searchOrders(): void {
        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(10),
            )
            .subscribe(res => {
                this.query.search = res;
                this._ordersService.getOrders(this.query).subscribe();
            });
    }

    openOrderView(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = id;
        this.dialogRef = this.dialog.open(OrderDetailsComponent, {
            data: dialogConfig.data
        })
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
