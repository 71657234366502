<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full">
        <div class="flex items-center justify-between w-full ml-3">
            <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{ t('products') }}</h2></div>
            <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                <mat-form-field
                    class="fuse-mat-dense fuse-mat-rounded min-w-64"
                    [subscriptSizing]="'dynamic'">
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input
                        matInput
                        [formControl]="searchInputControl"
                        [autocomplete]="'off'"
                        placeholder="{{t('searchProducts')}}">
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">
            <div class="table-container mat-elevation-z6 mt-2 sm:absolute tables-by-id"  style="padding-bottom: 65px; width: 100%;">
                <table mat-table [dataSource]="products || []" matSort
                       (matSortChange)="onSortChange($event)">
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef><b></b></th>
                        <td mat-cell *matCellDef="let product">
                            <div class="flex items-center">
                                <div class="relative flex
                                         flex-0 items-center justify-center w-12
                                         h-12 mr-6 rounded overflow-hidden
                                          border"
                                >
                                    <img
                                        class="w-8"
                                        *ngIf="product.gallery[0]"
                                        [alt]="'Product thumbnail image'"
                                        [src]="imagePath + product.gallery[0]?.thumbnail?.url">
                                    <div
                                        class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase"
                                        *ngIf="!product.gallery[0]">
                                        NO THUMB
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="productName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.productName">
                            <b>{{t('productName')}}</b></th>
                        <td mat-cell *matCellDef="let product">
                                    <span
                                        [matTooltip]="product?.name.length > 25 ? product.name : ''">{{product.name| textTruncate: 25}}  </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="sku">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('sku')}}</b></th>
                        <td mat-cell *matCellDef="let product">{{product.sku}}</td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('price')}}</b></th>
                        <td mat-cell *matCellDef="let product">{{product.price}}</td>
                    </ng-container>

                    <ng-container matColumnDef="reward">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('reward')}}</b></th>
                        <td mat-cell *matCellDef="let product">{{product.reward}}</td>
                    </ng-container>

                    <ng-container matColumnDef="sales">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.name"><b>{{t('sales')}}</b>
                        </th>
                        <td mat-cell *matCellDef="let product">
                            <div class="hidden lg:flex items-center">
                                <div class="min-w-4">{{product.sales}}</div>
                                <div
                                    class="flex items-end ml-2 w-1 h-4 bg-red-200 rounded overflow-hidden"
                                    *ngIf="product.sales < 20">
                                    <div class="flex w-full h-1/3 bg-red-600"></div>
                                </div>
                                <div
                                    class="flex items-end ml-2 w-1 h-4 bg-orange-200 rounded overflow-hidden"
                                    *ngIf="product.sales >= 20 && product.sales < 30">
                                    <div class="flex w-full h-2/4 bg-orange-400"></div>
                                </div>
                                <div
                                    class="flex items-end ml-2 w-1 h-4 bg-green-100 rounded overflow-hidden"
                                    *ngIf="product.sales >= 30">
                                    <div class="flex w-full h-full bg-green-400"></div>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                        <td mat-cell *matCellDef="let product">{{t(product.status)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let product">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="edit(product)">
                                    {{t('edit')}}
                                </button>
                                <button mat-menu-item (click)="delete(product.id)">
                                    {{t('delete')}}
                                </button>
                                <button mat-menu-item (click)="updateStatus(product)">
                                    {{t('updateStatus')}}
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator
                    style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                    class="paginator-by-id sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                    [ngClass]="{'pointer-events-none': isLoading}"
                    (page)="onPaginateChange($event)"
                    [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</ng-container>
