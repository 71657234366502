import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDrawer} from "@angular/material/sidenav";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {UserEntity, UserEntriesPageable} from "../../modules/user/user.types";
import {Meta} from "../../modules/customer/customer-types";
import {appConfig} from "../../core/config/app.config";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {UsersService} from "../../modules/user/user.service";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {ActivatedRoute, Router} from "@angular/router";
import {FuseMediaWatcherService} from "../../../@fuse/services/media-watcher";
import {SupplierService} from "../../modules/supplier/supplier.service";

@Component({
    selector: 'app-users-by-id',
    templateUrl: './users-by-id.component.html'
})

export class UsersByIdComponent implements OnInit {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    @ViewChild('matDrawer', {static: true}) matDrawer: MatDrawer;
    isLoading: boolean = false;
    drawerMode: 'side' | 'over';
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    users: UserEntity[];
    pagination: Meta;
    imagePath = appConfig.imagePath;
    selectedUserForm: UntypedFormGroup;
    selectedUser: any = null;
    query = {
        page: 0,
        limit: 10,
        search: '',
        supplier: this.supplierService.supplierId
    }
    displayedColumns = ['id', 'username', 'email', 'mobile', 'status', 'action'];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _usersService: UsersService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        private supplierService: SupplierService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
    ) {
    }

    ngOnInit(): void {
        this._usersService.pagination$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((pagination: Meta) => {
                this.pagination = pagination;
                this._changeDetectorRef.markForCheck();
            });
        this.getUsersList(this.query);
        this.searchUsers();
    }

    private getUsersList(query): void {
        this.isLoading = true;
        this._usersService.getUsers(query).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res: UserEntriesPageable) => {
                    this.users = res.payload.content;
                    this._changeDetectorRef.markForCheck();
                }
            }).add(() => this.isLoading = false)
    }

    private searchUsers(): void {
        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300))
            .subscribe({
                next: (res: string) => {
                    this.query.search = res;
                    this.getUsersList(this.query);
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onPaginateChange(event: any) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getUsersList(this.query);
    }
}
