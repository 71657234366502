import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {MatSort} from "@angular/material/sort";
import {Activity} from "./activities.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {ActivitiesService} from "./activities.service";
import {appConfig} from "../../core/config/app.config";

export enum ActivitiesLevelEnum {
    Log = 'log',
    Error = 'error'
}

@Component({
    selector       : 'activity',
    templateUrl    : './activities.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesComponent implements OnInit, OnDestroy
{
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    activities: any[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dateFormat = appConfig.dateFormat;
    displayedColumns = ['id', 'date', 'status', 'user', 'message'];
    query = {
        page: 1,
        limit: 25,
        orderBy: 'log.creationDate',
        orderDirection: 'desc'
    }
    activities$: Observable<Activity[]>;
    pagination: Pagination;
    searchInputControl: FormControl = new FormControl();
    isLoading: boolean = false;
    activitiesLevelType = ActivitiesLevelEnum;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public _activityService: ActivitiesService)
    {
    }

    ngOnInit(): void {
        this.getLogs(this.query);
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private getLogs(query): void {
        this.isLoading = true;
        this._activityService.getActivities(query).subscribe({
            next: (res) => {
                this.activities = res.payload.content;
                this.pagination = res.payload.pagination;
            }
        })
            .add(() => {
                this.isLoading = false;
                this._changeDetectorRef.markForCheck()
            })
    }

    onPaginateChange(event: PageEvent) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getLogs(this.query);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this.getLogs(this.query);
    }
}
