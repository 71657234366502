<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full">
        <div class="flex items-center justify-between w-full ml-3">
            <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{ t('payments') }}</h2></div>
        </div>
        <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">
            <div class="table-container mat-elevation-z6 mt-2 sm:absolute" style="padding-bottom: 65px; width: 100%;">
                <table mat-table matSort [dataSource]="recentTransactionsDataSource || []" (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="transactionId">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('paymentNumber')}}</b></th>
                        <td mat-cell *matCellDef="let transaction"> {{transaction.paymentNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.createdAt"><b>{{t('date')}}</b></th>
                        <td mat-cell
                            *matCellDef="let transaction"> {{transaction.createdAt |  date:dateFormat}} </td>
                    </ng-container>

                    <ng-container matColumnDef="orderNumber">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('orderNumber')}}</b></th>
                        <td mat-cell *matCellDef="let transaction"> {{transaction?.order?.orderNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="deposit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.deposit"><b>{{t('sum')}}</b></th>
                        <td mat-cell *matCellDef="let transaction">
                            {{transaction.deposit  | numberWithSpaces}} ₽</td>
                    </ng-container>

                    <ng-container matColumnDef="reward">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.reward"><b>{{t('reward')}}</b></th>
                        <td mat-cell *matCellDef="let transaction">{{(transaction.reward || 0) | numberWithSpaces}} <span
                            style="color: red">B </span></td>
                    </ng-container>

                    <ng-container matColumnDef="totalDeposit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.totalDeposit"><b>{{t('currentBalance')}} ₽</b></th>
                        <td mat-cell *matCellDef="let transaction">
                            {{(transaction.totalDeposit || 0) | numberWithSpaces}} ₽</td>
                    </ng-container>

                    <ng-container matColumnDef="totalReward">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.totalReward"><b>{{t('currentBalance')}} <span
                            style="color: red">B </span></b></th>
                        <td mat-cell *matCellDef="let transaction">{{(transaction.totalReward || 0) | numberWithSpaces}}<span
                            style="color: red"> B</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('type')}}</b></th>
                        <td mat-cell *matCellDef="let transaction"> {{t(transaction.type)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                        <td mat-cell *matCellDef="let transaction">
                                <span
                                    class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                    [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': transaction.status === 'pending',
                                                'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': transaction.status === 'success'}">
                                    <span class="leading-relaxed whitespace-nowrap">{{t(transaction.status)}}</span>
                                </span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let transaction; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator
                    style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                    class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                    [ngClass]="{'pointer-events-none': isLoading}"
                    [length]="pagination?.totalRecords"
                    [pageIndex]="pagination?.currentPage - 1"
                    (page)="pageEvent = $event; onPageChange($event)"
                    [pageSizeOptions]="[10, 25, 50, 100]"
                    [showFirstLastButtons]="true"></mat-paginator>
            </div>
        </div>
    </div>
</ng-container>
