import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {UserEntity, UserEntriesPageable} from "./user.types";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class UsersService {
    private _users: BehaviorSubject<UserEntity[] | null> = new BehaviorSubject(null);
    private _user: BehaviorSubject<UserEntity | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    get pagination$(): Observable<Pagination>
    {
        return this._pagination.asObservable();
    }

    get users$(): Observable<UserEntity[]>
    {
        return this._users.asObservable();
    }

    get user$(): Observable<UserEntity>
    {
        return this._user.asObservable();
    }

    getUsers(query):
        Observable<UserEntriesPageable>
    {
        return this._httpClient.get<UserEntriesPageable>(`${appConfig.baseUrl}/users/get-users`, {
            params: query
        }).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)
                this._users.next(response.payload.content);
            })
        );
    }

    getUserById(id: string): Observable<UserEntity>
    {
        return this._httpClient.get<any>(`${appConfig.baseUrl}/users/${id}`).pipe(
            tap((response) => {
                this._user.next(response.payload);
            })
        );
    }
}
