import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {appConfig} from "../../core/config/app.config";
import {SupplierEntriesPageable} from "../supplier/supplier-types";

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService
{
    private _activities: BehaviorSubject<any> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient)
    {
    }

    get pagination$(): Observable<Pagination>
    {
        return this._pagination.asObservable();
    }

    get activities$(): Observable<any>
    {
        return this._activities.asObservable();
    }

    getActivities(params):
        Observable<SupplierEntriesPageable>
    {
        return this._httpClient.get<SupplierEntriesPageable>(`${appConfig.baseUrl}/get-logs`, {
            params: params
        }).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)

                this._activities.next(response.payload.content);
            })
        );
    }
}
