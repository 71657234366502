import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'customers',
        title: 'customers',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/customers'
    },
    {
        id: 'suppliers',
        title: 'suppliers',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/suppliers'
    },
    {
        id: 'users',
        title: 'users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users'
    },
    {
        id: 'products',
        title: 'products',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
            {
                id: 'products',
                title: 'products',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/products/list'
            },
            {
                id: 'categories',
                title: 'categories',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'products/categories'
            }
        ]
    },
    {
        id: 'shops',
        title: 'shops',
        type: 'basic',
        icon: 'heroicons_outline:shopping-cart',
        link: '/shops'
    },
    {

        id: 'warehouses',
        icon: 'heroicons_outline:shopping-cart',
        title: 'warehouses',
        type: 'basic',
        link: '/warehouses'
    },
    {
        id: 'priceLists',
        icon: 'heroicons_outline:shopping-cart',
        title: 'priceLists',
        type: 'basic',
        link: '/price-lists'
    },
    {
        id: 'orders',
        title: 'orders',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/orders'
    },
    {
        id: 'payments',
        title: 'payments',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/payments'
    },
    {
        id: 'settings',
        title: 'settings',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        children: [
            {
                id: 'administrators',
                title: 'administrators',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/administrators'
            }, {
                id: 'security',
                title: 'security',
                type: 'basic',
                icon: 'heroicons_outline:lock-closed',
                link: 'settings/security'
            }, {
                id: 'planBilling',
                title: 'planBilling',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'settings/plan-billing'
            }, {
                id: 'roles',
                title: 'roles',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'settings/roles'
            }, {
                id: 'statuses',
                title: 'statuses',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'settings/statuses'
            }, {
                id: 'search',
                title: 'search',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'settings/search'
            },
            {
                id: 'globalSettings',
                title: 'globalSettings',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'settings/globalSettings'
            }
        ]
    },
    {
        id: 'activities',
        title: 'activities',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/activities'
    },
    {
        id: 'posts',
        title: 'posts',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        children: [
            {
                id: 'posts',
                title: 'posts',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'posts/posts-list'
            }, {
                id: 'categories',
                title: 'categories',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: 'posts/posts-categories'
            },
        ]
    },
    {
        id: 'signOut',
        title: 'signOut',
        type: 'basic',
        icon: 'heroicons_outline:logout',
        link: '/sign-out'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
