<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full">
        <div class="flex items-center justify-between w-full ml-3">
            <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{ t('activities') }}</h2></div>
        </div>
        <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">

            <div class="table-container mat-elevation-z6 mt-2 sm:absolute" style="padding-bottom: 65px; width: 100%;">
                <table mat-table matSort [dataSource]="activities || []" (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('id')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="log.creationDate"><b>{{t('date')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.creationDate | date: dateFormat}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                        <td [ngStyle]="{'color': log.level === activitiesLevelType.Error ? 'red' : '' }" mat-cell *matCellDef="let log"> {{t(log.level)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('user')}}</b></th>
                        <td  mat-cell *matCellDef="let log"> {{ (log.user?.email || log.user?.username || log.user?.mobile) | textTruncate: 26 }}
                            (ID: {{ log.user?.id | textTruncate: 26 }}) </td>
                    </ng-container>

                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('message')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.message | textTruncate: 56}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                [ngClass]="{'pointer-events-none': isLoading}"
                [length]="pagination?.totalRecords"
                (page)="onPaginateChange($event)"
                [pageIndex]="pagination?.currentPage - 1"
                [pageSizeOptions]="[10, 25, 100]"
                [showFirstLastButtons]="true"></mat-paginator>
        </div>
    </div>
</ng-container>
