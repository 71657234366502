<ng-container *transloco="let t">

    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

        <div
            class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

            <!-- Header -->
            <div
                class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
                <!-- Loader -->
                <div
                    class="absolute inset-x-0 bottom-0"
                    *ngIf="isLoading">
                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                </div>
                <!-- Title -->
                <div class="text-4xl font-extrabold tracking-tight">{{t('activities')}}</div>
                <!-- Actions -->
                <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                </div>
            </div>
            <div class="table-container mat-elevation-z6">
                <table mat-table matSort [dataSource]="activities || []" (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="log.id"><b>{{t('id')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="log.creationDate"><b>{{t('date')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.creationDate | date: dateFormat}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                        <td [ngStyle]="{'color': log.level === activitiesLevelType.Error ? 'red' : '' }" mat-cell *matCellDef="let log"> {{t(log.level)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('user')}}</b></th>
                        <td  mat-cell *matCellDef="let log"> {{ (log.user?.email || log.user?.username || log.user?.mobile) | textTruncate: 26 }}
                            (ID: {{ log.user?.id | textTruncate: 26 }}) </td>
                    </ng-container>

                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('message')}}</b></th>
                        <td mat-cell *matCellDef="let log"> {{log.message | textTruncate: 56}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                [ngClass]="{'pointer-events-none': isLoading}"
                [length]="pagination?.totalRecords"
                (page)="onPaginateChange($event)"
                [pageIndex]="pagination?.currentPage - 1"
                [pageSizeOptions]="[25, 50, 100]"
                [showFirstLastButtons]="true"></mat-paginator>
        </div>
    </div>
</ng-container>
