import {Injectable} from "@angular/core";
import {BehaviorSubject, filter, map, Observable, switchMap, take, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {ProductFeatured, ProductsContentItemModel, ProductsDataModel, ProductStatus} from "./product.types";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {appConfig} from "../../core/config/app.config";
import {AbstractControl, ValidationErrors, ɵElement, ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class ProductsService
{
    private _products: BehaviorSubject<ProductsContentItemModel[] | null> = new BehaviorSubject(null)
    private _product: BehaviorSubject<ProductsContentItemModel | null> = new BehaviorSubject(null)
    private _pagination: BehaviorSubject<PaginationModel | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient)
    {}

    get products$(): Observable<ProductsContentItemModel[]>
    {
        return this._products.asObservable();
    }

    get product$(): Observable<ProductsContentItemModel>
    {
        return this._product.asObservable();
    }

    get pagination$(): Observable<PaginationModel>
    {
        return this._pagination.asObservable();
    }

    getProducts(params):
        Observable<ApiResultModel<ProductsDataModel>>
    {
        return this._httpClient.get<ApiResultModel<ProductsDataModel>>(`api/products/get-products`, {params}).pipe(
            tap((response:ApiResultModel<ProductsDataModel>) => {
                this._pagination.next(response.payload.pagination);
                this._products.next(response.payload.content);
            })
        );
    }

    getProductById(id: string): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.get<ApiResultModel<ProductsContentItemModel>>(`api/products/get-products/${id}`).pipe(
            tap((response: ApiResultModel<ProductsContentItemModel>) => {
                this._product.next(response.payload);
            })
        )
    }

    createProduct(): Observable<ProductsContentItemModel>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.post<ProductsContentItemModel>('api/apps/ecommerce/inventory/product', {}).pipe(
                map((newProduct) => {
                    this._products.next([newProduct, ...products]);
                    return newProduct;
                })
            ))
        );
    }

    updateProduct(id: string, product: ProductsContentItemModel): Observable<ProductsContentItemModel>
    {
        return this._httpClient.patch<ProductsContentItemModel>(`api/products/update-products/${id}`, product).pipe(
            tap((response: ProductsContentItemModel) => {
                this._product.next(response);
            })
        )
    }

    updateProductStatus(id: string, status: ProductStatus): Observable<ProductStatus>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.put<ProductStatus>(`${appConfig.baseUrl}/products/update-product-status/${id}`, {
                status
            }).pipe(
                map((updatedProduct) => {
                    this._products.next(products);
                    return updatedProduct;
                }),
            ))
        );
    }

    deleteProduct(id: string): Observable<any>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.delete(`${appConfig.baseUrl}/products/delete-products/${id}`,).pipe(
                map((isDeleted: boolean) => {
                    const index = products.findIndex(item => item.id === id);
                    products.splice(index, 1);
                    this._products.next(products);
                    return isDeleted;
                })
            ))
        );
    }

    addProductFeatured(id: string, body): Observable<any> {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.post<ProductFeatured>(`${appConfig.baseUrl}/products/add-featured-product/${id}`, body).pipe(
                map((featuredProduct) => {

                    this._products.next(products);

                    return featuredProduct;
                }),
            ))
        );
    }

    getProductFeatureBySection(id: string, section: string): Observable<any> {
        return this._httpClient.get(`${appConfig.baseUrl}/products/get-product-feature-by-section/${id}/${section}`);
    }
}

