import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProductsContentItemModel} from "../../modules/products/product.types";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {UntypedFormControl} from "@angular/forms";
import {appConfig} from "../../core/config/app.config";
import {SupplierService} from "../../modules/supplier/supplier.service";
import {ProductsService} from "../../modules/products/products.service";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {environment} from "../../../environments/environment";
import {ConfirmPopupComponent} from "../confirm-popup/confirm-popup.component";
import {UpdateProductStatusComponent} from "../../modules/products/update-product-status/update-product-status.component";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-products-by-id',
    templateUrl: './products-by-id.component.html'
})

export class ProductsByIdComponent implements OnInit{
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    products: ProductsContentItemModel[];
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    pagination: PaginationModel;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    imagePath = environment.imagePath;
    categories: any[];
    displayedColumns = ['image', 'productName', 'sku', 'price', 'reward', 'sales', 'active', 'details'];
    query = {
        page: 1,
        limit: 10,
        orderBy: 'p.id',
        orderDirection: 'asc',
        search: '',
        supplier: ''
    }

    constructor(private supplierService: SupplierService,
                private _productsService: ProductsService,
                private _changeDetectorRef: ChangeDetectorRef,
                public _matDialog: MatDialog) {
    }

    ngOnInit() {
        this.query.supplier = this.supplierService.supplierId;
        this.getProducts(this.query);
        this.searchProducts();
    }

    private getProducts(query): void {
        this.isLoading = true;
        this._productsService.getProducts(query)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res) => {
                    this.products = res.payload.content;
                    this.pagination = res.payload.pagination;
                    this._changeDetectorRef.markForCheck();
                }
            })
            .add(() => this.isLoading = false)
    }

    onPaginateChange(event: any) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getProducts(this.query);
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this.getProducts(this.query);
    }

    private searchProducts(): void {
        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(10),
            )
            .subscribe(res => {
                this.query.search = res;
                this.getProducts(this.query);
            });
    }

    edit(product: ProductsContentItemModel): void {

    }

    delete(id: string): void {
        const dialog = this._matDialog.open(ConfirmPopupComponent, {
            data: {
                title: 'agreeDeleteProduct'
            },
        });

        dialog.afterClosed().pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res) => {
                    if (res) {
                        this.deleteSelectedProduct(id);
                    }
                }
            })
    }

    deleteSelectedProduct(id: string): void {
        this._productsService.deleteProduct(id).subscribe((res) => {
            this.getProducts(this.query)
        });
    }

    updateStatus(product: any): void {
        const dialog = this._matDialog.open(UpdateProductStatusComponent, {
            data: product
        });

        dialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (res: any) => {
                if (res) {
                    this.getProducts(this.query);
                }
            }
        })
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
