import {Injectable} from "@angular/core";
import {BehaviorSubject, map, Observable, take, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {
    CreateEditSupplierReqModel, CreateEditSupplierResModel,
    Meta,
    SupplierEntity,
    SupplierEntriesPageable,
    SupplierModel
} from "./supplier-types";

@Injectable({
    providedIn: 'root'
})
export class SupplierService
{
    private _suppliers: BehaviorSubject<SupplierEntity[] | null> = new BehaviorSubject(null)
    private _supplier: BehaviorSubject<SupplierModel | null> = new BehaviorSubject(null)
    private _pagination: BehaviorSubject<Meta | null> = new BehaviorSubject(null);
    supplierId: string;

    constructor(private _httpClient: HttpClient)
    {}

    get suppliers$(): Observable<SupplierEntity[]>
    {
        return this._suppliers.asObservable();
    }

    get supplier$(): Observable<SupplierModel>
    {
        return this._supplier.asObservable();
    }

    get pagination$(): Observable<Meta>
    {
        return this._pagination.asObservable();
    }

    getSuppliers(query):
        Observable<SupplierEntriesPageable>
    {
        return this._httpClient.get<SupplierEntriesPageable>(`api/suppliers/get-suppliers`, {
            params: query
        }).pipe(
            tap((response:SupplierEntriesPageable) => {
                this._pagination.next(response.payload.pagination);
                this._suppliers.next(response.payload.content);
            })
        );
    }

    getSupplierById(id: string): Observable<ApiResultModel<SupplierModel>> {
        return this._httpClient.get<ApiResultModel<SupplierModel>>(`api/suppliers/get-supplier/${id}`).pipe(
            tap((response: ApiResultModel<SupplierModel>) => {
                this._supplier.next(response.payload);
            })
        )
    }

    updateSuppliers(id: string, data: CreateEditSupplierReqModel): Observable<ApiResultModel<CreateEditSupplierResModel>> {
        return this._httpClient.patch<ApiResultModel<CreateEditSupplierResModel>>(`api/suppliers/update-supplier/${id}`, data);
    }
}

