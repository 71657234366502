import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {UntypedFormControl, Validators} from "@angular/forms";
import {ProductsService} from "../products.service";

@Component({
    selector: 'app-update-product-status',
    templateUrl: './update-product-status.component.html'
})
export class UpdateProductStatusComponent {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    statusControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

    productStatusList: any[] = [
        {
            value: 'in-review',
            name: 'in-review'
        },
        {
            value: 'approved',
            name: 'approved'
        },
        {
            value: 'declined',
            name: 'declined'
        },
        {
            value: 'blocked',
            name: 'blocked'
        }
    ];

    constructor(public _matDialogRef: MatDialogRef<UpdateProductStatusComponent>,
                private _productsService: ProductsService,
                @Inject(MAT_DIALOG_DATA) public _data: any,
    ) {
            this.statusControl.setValue(this._data.status);
        console.log(this.statusControl.value)
    }

    confirm(): void {
        this._productsService.updateProductStatus(this._data.id, this.statusControl.value).subscribe((status) => {
            this._matDialogRef.close(status);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
