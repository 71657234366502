import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)}
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'suppliers', loadChildren: () => import('app/modules/supplier/supplier.module').then(m => m.SupplierModule)},
            {path: 'customers', loadChildren: () => import('app/modules/customer/customer.module').then(m => m.CustomerModule)},
            {path: 'users', loadChildren: () => import('app/modules/user/user.module').then(m => m.UserModule)},
            {path: 'shops', loadChildren: () => import('app/modules/shops/shops.module').then(m => m.ShopsModule)},
            {path: 'accounts', loadChildren: () => import('app/modules/accounts/accounts.module').then(m => m.AccountsModule)},
            {path: 'products', loadChildren: () => import('app/modules/products/products.module').then(m => m.ProductsModule)},
            {path: 'orders', loadChildren: () => import('app/modules/orders/orders.module').then(m => m.OrdersModule)},
            {path: 'payments', loadChildren: () => import('app/modules/payments/payments.module').then(m => m.PaymentsModule)},
            {path: 'settings', loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'activities', loadChildren: () => import('app/modules/activities/activities.module').then(m => m.ActivitiesModule)},
            {path: 'posts', loadChildren: () => import('app/modules/posts/posts.module').then(m => m.PostsModule)},
            {path: 'warehouses', loadChildren: () => import('app/modules/warehouses/warehouses.module').then(m => m.WarehousesModule)},
            {path: 'price-lists', loadChildren: () => import('app/modules/price-lists/price-lists.module').then(m => m.PriceListsModule)},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
