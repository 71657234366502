<ng-container *transloco="let t">
    <h2><b>{{t('updateStatus')}}</b></h2>
    <mat-dialog-content class="mat-typography">
        <mat-form-field appearance="outline" class="w-full mat-form-field mt-5">
            <mat-label>{{t('productStatus')}}</mat-label>
            <mat-select [formControl]=statusControl>
                <mat-option *ngFor="let status of productStatusList" [value]=" status.value">{{ t(status.name) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="flex items-center justify-end">
            <button
                mat-stroked-button
                type="button" (click)="_matDialogRef.close()">
                {{t('cancel')}}
            </button>
            <button
                class="fuse-mat-button-large ml-4"
                [disabled]="statusControl.invalid"
                mat-flat-button
                [color]="'primary'"
                (click)="confirm()"
            >
                {{t('confirm')}}
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
