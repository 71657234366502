import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError} from "rxjs";
import {OrderEntity, OrderEntriesPageable} from "./order.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import { appConfig } from "app/core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class OrdersService {
    private _orders: BehaviorSubject<OrderEntity[] | null> = new BehaviorSubject(null);
    private _order: BehaviorSubject<OrderEntity | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    get pagination$(): Observable<Pagination>
    {
        return this._pagination.asObservable();
    }

    get orders$(): Observable<OrderEntity[]>
    {
        return this._orders.asObservable();
    }

    get order$(): Observable<OrderEntity>
    {
        return this._order.asObservable();
    }

    getOrders(params):
        Observable<OrderEntriesPageable>
    {
        return this._httpClient.get<OrderEntriesPageable>(`${appConfig.baseUrl}/orders/get-orders`, {
            params: params
        }).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)

                this._orders.next(response.payload.content);
            })
        );
    }

    getOrderById(id: string): Observable<OrderEntity>
    {
        return this._httpClient.get<any>(`${appConfig.baseUrl}/orders/get-order/${id}`).pipe(
            tap((response) => {
                this._order.next(response.payload);
            }),
            switchMap((order) => {

                if ( !order )
                {
                    return throwError('Could not found product with id of ' + id + '!');
                }

                return of(order.payload);
            })
        );
    }
}
