import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, switchMap, tap, throwError} from "rxjs";
import {ShopEntity, ShopEntriesPageable} from "./shops.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class ShopsService {
    private _shops: BehaviorSubject<ShopEntity[] | null> = new BehaviorSubject(null);
    private _shop: BehaviorSubject<ShopEntity | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    get pagination$(): Observable<Pagination>
    {
        return this._pagination.asObservable();
    }

    get shops$(): Observable<ShopEntity[]>
    {
        return this._shops.asObservable();
    }

    get shop$(): Observable<ShopEntity>
    {
        return this._shop.asObservable();
    }

    getShops(params):
        Observable<ShopEntriesPageable>
    {
        return this._httpClient.get<ShopEntriesPageable>(`${appConfig.baseUrl}/shops/get-shops`, {
            params: params
        }).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)

                this._shops.next(response.payload.content);
            })
        );
    }

    getShopById(id: string): Observable<ShopEntity>
    {
        return this._httpClient.get<any>(`${appConfig.baseUrl}/shops/get-shops/${id}`).pipe(
            tap((response) => {
                console.log(response)
                this._shop.next(response.payload);
            }),
            switchMap((shop) => {

                if ( !shop )
                {
                    return throwError('Could not found shop with id of ' + id + '!');
                }

                return of(shop.payload);
            })
        );
    }
}
