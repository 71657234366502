import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SupplierModel} from "../../modules/supplier/supplier-types";
import {ApexOptions} from "ng-apexcharts";
import {MatTableDataSource} from "@angular/material/table";
import {Subject} from "rxjs";
import {appConfig} from "../../core/config/app.config";
import {PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {UserService} from "../../core/user/user.service";
import {PaymentsService} from "../../modules/payments/payments.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-payments-by-id',
    templateUrl: './payments-by-id.component.html'
})

export class PaymentsByIdComponent implements OnInit{
    @Input() customerId: string;
    @Input() supplierId: string;
    user: SupplierModel;
    accountBalanceOptions: ApexOptions;
    recentTransactionsDataSource: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns: string[] = ['transactionId', 'date', 'orderNumber', 'deposit', 'reward', 'totalDeposit', 'totalReward', 'type', 'status'];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dateFormat = appConfig.dateFormat;
    isLoading: boolean;
    pagination: any;
    pageEvent: PageEvent;
    query = {
        page: 1,
        limit: 25,
        orderBy: 'p.createdAt',
        orderDirection: 'desc',
        search: '',
        customer: '',
        supplier: ''
    }

    constructor(private router: Router,
                private _userService: UserService,
                private paymentService: PaymentsService,
                public _matDialog: MatDialog,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.query.customer = this.customerId;
        this.query.supplier = this.supplierId;
        this.getAccountPayments(this.query);
    }

    private getAccountPayments(query): void {
        this.isLoading = true;
        this.paymentService.getPayments(query).subscribe({
            next: res => {
                this.recentTransactionsDataSource.data = res.payload.content;
                this.pagination = res.payload.pagination;
            }
        })
            .add(() => {
                this.isLoading = false;
                this.cdRef.markForCheck();
            })
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onPageChange(event: any): void {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getAccountPayments(this.query);
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this.getAccountPayments(this.query);
    }
}
