<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full">
        <div class="flex items-center justify-between w-full ml-3">
            <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{t('partners')}}</h2></div>
            <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                <mat-form-field class="fuse-mat-dense fuse-mat-rounded min-w-64" [subscriptSizing]="'dynamic'">
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input
                        matInput (keyup)="applyPartnersFilter($event.target.value)"
                        [autocomplete]="'on'"
                        placeholder="{{t('searchPartners')}}">
                </mat-form-field>

            </div>
        </div>
        <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">
            <div class="table-container mat-elevation-z6 mt-2 sm:absolute" style="padding-bottom: 65px; width: 100%;">
                <table mat-table [dataSource]="partners || []">

                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('customer')}}</b></th>
                        <td mat-cell *matCellDef="let partner">
                                    <span
                                        [matTooltip]="(partner?.customer.person.firstName + ' ' + partner?.customer.person.lastName).length > 20 ? (partner?.customer.person.firstName + ' ' + partner?.customer.person.lastName) : ''">
                                        {{(partner?.customer.person.firstName + ' ' + partner?.customer.person.lastName)  | textTruncate: 20}} </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clientCode">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('clientCode')}}</b></th>
                        <td mat-cell *matCellDef="let partner"> {{partner?.shopRefId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="shop">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('shop')}}</b></th>
                        <td mat-cell *matCellDef="let partner">
                            <span
                                [matTooltip]="partner?.shop?.shopName.length > 20 ? partner?.shop?.shopName : ''">{{partner.shop.shopName | textTruncate: 20}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="priceList">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('priceList')}}</b></th>
                        <td mat-cell *matCellDef="let partner">
                            <span
                                [matTooltip]="partner?.priceList?.name.length > 20 ? partner?.priceList?.name : ''">{{partner?.priceList?.name | textTruncate: 20}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="warehouse">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('warehouse')}}</b></th>
                        <td mat-cell *matCellDef="let partner">
                            <span
                                [matTooltip]="partner?.warehouse?.name.length > 20 ? partner?.warehouse?.name : ''">{{partner?.warehouse?.name | textTruncate: 20}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acceptedAt">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('acceptedAt')}}</b></th>
                        <td mat-cell *matCellDef="let partner"> {{partner?.acceptedAt | date: dateFormat}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator
                style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                [ngClass]="{'pointer-events-none': isLoading}"
                [length]="pagination?.totalRecords"
                [pageIndex]="pagination?.currentPage - 1"
                (page)="pageEvent = $event; onPartnersPaginateChange($event)"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [showFirstLastButtons]="true"></mat-paginator>
        </div>
    </div>
</ng-container>
