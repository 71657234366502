import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {appConfig} from "../../core/config/app.config";
import {Observable, Subject} from "rxjs";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {HttpClient} from "@angular/common/http";
import {UntypedFormControl} from "@angular/forms";
import {PaginationModel} from "../../core/interfaces/pagination.interface";

@Component({
    selector: 'app-partnership-by-id',
    templateUrl: './partnership-by-id.component.html'
})
export class PartnershipByIdComponent implements OnInit{
    @Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Input() customerId: string;
    @Input() supplierId: string;
    partners$: Observable<any[]>;
    partners: any[];
    imagePath = appConfig.imagePath;
    drawerMode: 'side' | 'over';
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    partnersPagination: any;
    requestsPagination: any;
    pageEvent: PageEvent;
    pagination: PaginationModel;
    partnersSearchLetter: string;
    requestsSearchLetter: string;
    isLoading: boolean;
    query = {
        search: '',
        page: 1,
        limit: 10,
        customer: '',
        supplier: ''
    };
    dateFormat = appConfig.dateFormat;
    displayedColumns = ['customer', 'clientCode', 'shop', 'priceList', 'warehouse', 'acceptedAt'];
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _httpClient: HttpClient
    )
    {
    }
    ngOnInit(): void
    {
        this.query.customer = this.customerId;
        this.query.supplier = this.supplierId;
        this.getPartners(this.query);
    }

    private getPartners(query): void {
        this.isLoading = true;
        this.getPartnersApi(query)
            .subscribe({
                next: (res) => {
                    this.partners = res.payload.content;
                    this.pagination = res.payload.pagination;
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                }
            })
            .add(() => this.isLoading = false)
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    applyPartnersFilter(value: string): void {
        this.query.search = value;
        this.getPartners(this.query);
    }

    onPartnersPaginateChange(event: PageEvent): void {
        event.pageIndex = event.pageIndex + 1;
        this.paginate.emit(event);
        this.query.page = event.pageIndex;
        this.query.limit = event.pageSize;
        this.getPartners(this.query);
    }

    getPartnersApi(query): Observable<ApiResultModel<any>>
    {
        return this._httpClient.get<ApiResultModel<any>>(`api/partnerships/get-partnerships`, {params: query});
    }
}
