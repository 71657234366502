<ng-container *transloco="let t">
    <h2 mat-dialog-title>{{t(data?.title)}}</h2>
    <mat-dialog-content class="mat-typography">
        <p>{{t(data?.message)}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="flex items-center justify-end">
            <button
                mat-stroked-button
                type="button" (click)="closeDialog()">
                {{t('cancel')}}
            </button>
            <button
                class="fuse-mat-button-large ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="confirm()"
            >
                {{t('confirm')}}
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
