<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full">
        <div class="flex items-center justify-between w-full ml-3">
            <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{ t('orders') }}</h2></div>
            <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                <mat-form-field class="fuse-mat-dense fuse-mat-rounded min-w-64" [subscriptSizing]="'dynamic'">
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input
                        matInput
                        [formControl]="searchInputControl"
                        [autocomplete]="'off'"
                        placeholder="{{t('searchOrders')}}">
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">
            <div class="table-container mat-elevation-z6 mt-2 sm:absolute" style="padding-bottom: 65px; width: 100%;">
                <table mat-table [dataSource]="(orders$ | async) || []" matSort
                       (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="p.createdAt">
                            <b>{{t('createdAt')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{order.createdAt| date:'yyyy-MM-dd'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="updatedAt">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('updatedAt')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{order.updatedAt | date:'yyyy-MM-dd'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="orderNumber">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('orderNumber')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{order.orderNumber}}</td>
                    </ng-container>

                    <ng-container matColumnDef="orderTotal">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('orderTotal')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{order.orderTotal | numberWithSpaces}} ₽</td>
                    </ng-container>

                    <ng-container matColumnDef="companyName">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('companyName')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{order.companyName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="rewardTotal">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('rewardTotal')}}</b></th>
                        <td mat-cell *matCellDef="let order"> {{(order.rewardTotal  | numberWithSpaces) || 0}}
                            <span style="color: red"> B</span></td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                        <td mat-cell *matCellDef="let order">{{t(order.status)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let order">
                            <button matTooltip="{{t('view')}}" mat-icon-button
                                    (click)="openOrderView(order.id)">
                                <mat-icon>wysiwyg
                                </mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator
                    style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                    class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                    [ngClass]="{'pointer-events-none': isLoading}"
                    (page)="onPaginateChange($event)"
                    [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</ng-container>
