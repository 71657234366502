import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDrawer} from "@angular/material/sidenav";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {debounceTime, Observable, Subject, takeUntil} from "rxjs";
import {ShopEntity} from "../../modules/shops/shops.types";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {FormControl} from "@angular/forms";
import {ShopsService} from "../../modules/shops/shops.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {FuseMediaWatcherService} from "../../../@fuse/services/media-watcher";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-shops-by-id',
    templateUrl: './shops-by-id.component.html'
})

export class ShopsByIdComponent implements OnInit {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    @Input() customerId: string;
    @Input() supplierId: string;
    @Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    shops$: Observable<ShopEntity[]>;
    pagination: Pagination;
    searchInputControl: FormControl = new FormControl();
    displayedColumns = ['shopId', 'taxId', 'shopName', 'companyName'];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isLoading: boolean = false;
    imagePath: string;
    query = {
        page: 1,
        limit: 10,
        orderBy: 's.id',
        orderDirection: 'asc',
        search: '',
        customer: '',
        supplier: ''
    }

    constructor(
        private _shopsService: ShopsService,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {}

    ngOnInit(): void
    {
        this.query.customer = this.customerId;
        this.query.supplier = this.supplierId;
        this.imagePath = environment.imagePath;
        this.shops$ = this._shopsService.shops$;
        this._shopsService.getShops(this.query).subscribe();
        this._shopsService.pagination$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((pagination: Pagination) => {
                this.pagination = pagination;
                this._changeDetectorRef.markForCheck();
            })

        this.searchShops();
    }

    private searchShops(): void {
        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(10),
            )
            .subscribe(res => {
                this.query.search = res;
                this._shopsService.getShops(this.query).subscribe();
            });
    }

    onSortChange(event: { active: string, direction: string }): void {
        this.query.orderBy = event.active;
        this.query.orderDirection = event.direction;
        this._shopsService.getShops(this.query).subscribe();
    }

    onPaginateChange(event: any) {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this._shopsService.getShops(this.query).subscribe();
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
