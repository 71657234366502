<ng-container *transloco="let t">
    <div class="flex items-center justify-between w-full ml-3">
        <div><h2 class="text-3xl font-semibold tracking-tight leading-8">{{ t('users') }}</h2></div>
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded min-w-64"
                [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input
                    matInput
                    [formControl]="searchInputControl"
                    [autocomplete]="'off'"
                    placeholder="{{t('searchUsers')}}">
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-3">
        <div class="table-container mat-elevation-z6 sm:absolute mt-2" style="padding-bottom: 65px; width: 100%;">
            <table mat-table [dataSource]="users || []">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef><b>{{t('id')}}</b></th>
                    <td mat-cell *matCellDef="let user">{{user.id}}</td>
                </ng-container>

                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef><b>{{t('username')}}</b></th>
                    <td mat-cell *matCellDef="let user">{{user.username}}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef><b>{{t('email')}}</b></th>
                    <td mat-cell *matCellDef="let user">{{user.email}}</td>
                </ng-container>

                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef><b>{{t('mobile')}}</b></th>
                    <td mat-cell *matCellDef="let user">{{user.mobile}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef><b>{{t('status')}}</b></th>
                    <td mat-cell *matCellDef="let user">{{t(user.status)}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <div>
                            <!--                                        <button matTooltip="{{t('view')}}" mat-icon-button-->
                            <!--                                                [routerLink]="['./', user.id]">-->
                            <!--                                            <mat-icon>wysiwyg-->
                            <!--                                            </mat-icon>-->
                            <!--                                        </button>-->
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator
                style="position: fixed !important; left: 283px !important; bottom: 0 !important;"
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                [ngClass]="{'pointer-events-none': isLoading}"
                (page)="onPaginateChange($event)"
                [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
                showFirstLastButtons></mat-paginator>
        </div>
    </div>

    <div class="flex flex-col flex-auto w-full">
    </div>
</ng-container>

