import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class PaymentsService {
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
    private _payments: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    get pagination$(): Observable<Pagination>
    {
        return this._pagination.asObservable();
    }

    get payments(): Observable<any[]>
    {
        return this._payments.asObservable();
    }

    getPayments(query):
        Observable<any>
    {
        return this._httpClient.get<any>(`${appConfig.baseUrl}/payments/get-payments`, {params: query}).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)

                this._payments.next(response.payload.content);
            })
        );
    }
}

